.App{
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #dadbd3;
}

.App_body{
  display: flex;
  background-color: #ededed;
  margin-top: 50px;
  height: 90vh;
  width: 90vw;
  box-shadow: -1px 4px 20px -6px rgba(0,0,0.75);

}
.form{
  margin-top: 3;
  width: 100%;
}
.submit{
  height: 60px;
  background-color: #a6ff4d;
}
.instructions_para{
  font-size: 14px;
  color: rgb(148, 148, 148);
  padding: 5px;
}