.Chat{
    display:flex;
    flex-direction: column;
    flex: 0.65;
}
.chat_header{
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
}

.chat_headerInfo{
    flex: 1;
    padding-left: 20px;
}
.chat_body{
    flex:1;
    background-image: url('https://i.pinimg.com/736x/8c/98/99/8c98994518b575bfd8c949e91d20548b.jpg');
    background-repeat: repeat;
    background-position: center;
    padding: 30px;
    overflow: scroll;
}
.chat_message{
    position: relative;
    font-size: 16px;
    padding: 10px;
    width: fit-content;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 30px;
}
.chat_message_emoji_and_voice{
    position: relative;
    font-size: 25px;
    padding: 10px;
    width: fit-content;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 30px;
}
.chat_reciever{
    margin-left: auto;
    background-color: #dcf8c6;
}
.chat_timestamp{
    margin-left: 10px;
    font-size: xx-small;
}
.chat_name{
    position: absolute;
    top: -15px;
    font-weight: 800;
    font-size: xx-small;
}
.chat_footer{
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 62px;
   border-top: 1px solid lightgray; 
}

.chat_footer > form{
    flex: 1;
    display: flex;
}

.chat_footer >form >input{
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
    outline-style: none;
}

.chat_footer >form>button{
    display: none;
}
.chat_footer > .MuiSvgIcon-root{
    padding: 10px;
    color: gray;
}
.voiceRecording_div{
    flex: 1;
    align-items: center;
    display: flex;
}
.voiceRecording_div>p{
    text-align: start;
    flex: 1;
}
.chat_search_box{
    height: 35px;
}
.chat_headerRight{  
    display: flex;
    align-items: center;
}